import { FaLinkedin, FaFacebook, FaBehance } from "react-icons/fa";
import MvLogo from "./MvLogo";
import { Container } from "react-bootstrap";


export default function SocialRow() {
    return (
        <>
                   <Container className="d-flex"
        style={{height:"20px"}}
        >

        <a
        className="SocialStyle me-1 link-light"
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.linkedin.com/in/jamesgerhold"
        >
        <FaLinkedin />
        </a>

        <a
        className="SocialStyle me-1 link-light"
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/mediavelocity"
        >
        <FaFacebook />
      </a>
      <a
        className="SocialStyle me-1 link-light"
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.behance.net/jamesgerhold"
        >
        <FaBehance />
       </a>
      {/* <a
        className="SocialStyle me-1 link-light"
        rel="noopener noreferrer"
        target="_blank"
        href="https://github.com/monkeyElbow"
        >
        <FaGithub />
       </a> */}
      <a
        className="SocialStyle me-1 link-light"
        rel="noopener noreferrer"
        target="_blank"
        href="https://mediavelocity.com/"
        >
        <MvLogo color="#800" />
       </a>
          </Container> 
        </>
    )
}
