export default function MvLogo(color) {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 200 200"
          overflow="visible"
        >
          <g
            fill="none"
            // stroke="#ae0000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="18"
            strokeDasharray="600"
            stroke={color}
          >
            <path d="M66.46 66.57v66.86M100 66.57v66.86" />
            <path d="M162.69 147.22a78.34 78.34 0 1 0-29.57 23.95l.42-.2V66.57" />
          </g>
        </svg>
      </>
    );
  }
  