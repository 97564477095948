import Home from './home';
import './App.css';
import ReactGA from "react-ga4";

function App() {

  ReactGA.initialize("G-NS25RBN03Y");
  ReactGA.send({ hittype:"pageview", page: window.location.pathname });

  return (
    <>
  
    <Home />
    </>
  );
}

export default App;
